import React, { useState } from "react"
/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"
import { motion } from "framer-motion"
import Personal from "../../components/about/personal/Personal"
import Professional from "../../components/about/professional"
import { useAppContext } from "../../context/AppContext"

const aboutWrapper = {
  position: "absolute",
  overflow: "hidden",
  top: 0,
  left: 0,
  width: "100%",
  // border: "1px solid red",
}

const baseCategoryData = [
  {
    name: "personal",
    selected: true,
    tooltip: { title: "Personal", position: "bottom" },
    page: <Personal />,
  },
  {
    name: "professional",
    selected: true,
    tooltip: { title: "Professional", position: "bottom" },
    page: <Professional />,
  },
  // {
  //   name: "professional",
  //   selected: false,
  //   tooltip: { title: "Professional", position: "bottom" },
  //   pageSections: [
  //     {
  //       name: "education",
  //       selected: true,
  //       tooltip: { title: "Education", position: "left" },
  //     },
  //     {
  //       name: "early",
  //       selected: false,
  //       tooltip: { title: "Early", position: "left" },
  //     },
  //     {
  //       name: "growth",
  //       selected: false,
  //       tooltip: { title: "Growth", position: "left" },
  //     },
  //     {
  //       name: "growth1",
  //       selected: false,
  //       tooltip: { title: "Growth1", position: "left" },
  //     },
  //   ],
  //   page: <Professional />,
  // },
]

const About = props => {
  const { size } = useAppContext()
  const [categoryData, setCategoryData] = useState(baseCategoryData)
  const categoryIndex = categoryData.reduce((acc, cur, i) => {
    if (cur.selected) {
      acc.push(i)
    }
    return acc
  }, [])[0]

  return (
    <Box sx={aboutWrapper}>
      <motion.div
        animate={{ x: -1 * categoryIndex * size.viewport.width }}
        intial={{ x: 0 }}
        transition={{
          x: { type: "spring", stiffness: 50, duration: 0.7 },
        }}
        sx={{
          display: "flex",
          width: (categoryData.length + 1) * size.viewport.width,
        }}
      >
        {categoryData.map((val, i) => {
          return (
            <Flex
              key={i}
              sx={{
                width: size.viewport.width,
                flexDirection: "column",
              }}
            >
              {val.page}
            </Flex>
          )
        })}
      </motion.div>
    </Box>
  )
}

About.defaultProps = {}

export default About
