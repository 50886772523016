import React, { useRef } from "react"
import { motion, useViewportScroll, useTransform } from "framer-motion"
/** @jsx jsx */
import { jsx, Box } from "theme-ui"

// const textStyle = {
//   // fontSize: "48px",
//   zIndex: 10000,
// }

const ParallaxHorizontal = props => {
  const { vx } = props.positionData
  const textStyle = props.style
  const { scrollY } = useViewportScroll()
  const ref = useRef(null)
  let vxEnd = vx.end
  const offset = vx.end < 900 ? 20 : 50
  if (ref.current) {
    vxEnd = vx.end - ref.current.clientWidth - offset
  }
  const x = useTransform(
    scrollY,
    [vx.scroll.start, vx.scroll.end],
    [vx.start, vxEnd],
    { clamp: vx.clamp }
  )

  return (
    <Box sx={{ width: "fit-content" }}>
      <motion.div ref={ref} style={{ ...textStyle, x }}>
        {props.children}
      </motion.div>
    </Box>
  )
}
ParallaxHorizontal.defaultProps = {
  vx: {
    scroll: {
      start: 0,
      end: 1,
    },
    start: 0,
    end: 1,
    speed: 1,
    clamp: false,
  },
}
export default ParallaxHorizontal
