import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

const PassionText = props => {
  const style = props.style

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 125.51 21.87"
      sx={style}
    >
      <g>
        <path
          d="M37.62,21.78c-.18,0-.28,0-.37-.09a.33.33,0,0,1-.09-.27V18.59c0-.27.09-.36.46-.45a2.84,2.84,0,0,0,1.18-.37,2.69,2.69,0,0,0,1-.82,2.27,2.27,0,0,0,.73-1.09,2.83,2.83,0,0,0,.28-1.28V7.38a6.7,6.7,0,0,1,.45-2.73,8.27,8.27,0,0,1,1.46-2.28A7.28,7.28,0,0,1,44.91.82,6.64,6.64,0,0,1,47.55.09.43.43,0,0,1,48,.55V3.28c0,.09-.09.18-.09.27a.47.47,0,0,1-.37.19,3.3,3.3,0,0,0-2.28,1.18,3.74,3.74,0,0,0-.91,2.46v7.2a7.42,7.42,0,0,1-.54,2.74,9.06,9.06,0,0,1-1.37,2.18,7.28,7.28,0,0,1-2.19,1.55A6.64,6.64,0,0,1,37.62,21.78Z"
          sx={{ fill: "primary" }}
        />
        <path
          d="M38.71,19.5v.55a5.67,5.67,0,0,0,4.19-5.47V7.38a5.2,5.2,0,0,1,3.56-5V1.82a5.7,5.7,0,0,0-4.2,5.56v7.2a5.18,5.18,0,0,1-3.55,4.92Z"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M53.46,21.78c-.18,0-.27,0-.36-.09a.33.33,0,0,1-.09-.27V18.59c0-.27.09-.36.45-.45a2.88,2.88,0,0,0,1.19-.37,2.77,2.77,0,0,0,1-.82,2.27,2.27,0,0,0,.73-1.09,3,3,0,0,0,.27-1.28V7.38a6.54,6.54,0,0,1,.46-2.73,8,8,0,0,1,1.46-2.28A7.16,7.16,0,0,1,60.76.82,6.64,6.64,0,0,1,63.4.09a.44.44,0,0,1,.46.46V3.28c0,.09-.1.18-.1.27a.45.45,0,0,1-.36.19,3.3,3.3,0,0,0-2.28,1.18,3.69,3.69,0,0,0-.91,2.46v7.2a7.24,7.24,0,0,1-.55,2.74A8.73,8.73,0,0,1,58.3,19.5a7.28,7.28,0,0,1-2.19,1.55A6.64,6.64,0,0,1,53.46,21.78Z"
          sx={{ fill: "primary" }}
        />
        <path
          d="M54.56,19.5v.55a5.67,5.67,0,0,0,4.19-5.47V7.38a5.2,5.2,0,0,1,3.56-5V1.82a5.7,5.7,0,0,0-4.2,5.56v7.2a5.19,5.19,0,0,1-3.55,4.92Z"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M115.12,21.78c-.18,0-.27,0-.36-.09a.31.31,0,0,1-.1-.27V18.59c0-.27.1-.36.46-.45a2.84,2.84,0,0,0,1.18-.37,2.8,2.8,0,0,0,1-.82,2.42,2.42,0,0,0,.73-1.09,3,3,0,0,0,.27-1.28V7.38a6.54,6.54,0,0,1,.46-2.73,8.25,8.25,0,0,1,1.45-2.28A7.28,7.28,0,0,1,122.41.82a6.64,6.64,0,0,1,2.64-.73.43.43,0,0,1,.46.46V3.28c0,.09-.09.18-.09.27a.45.45,0,0,1-.37.19,3.26,3.26,0,0,0-2.27,1.18,3.7,3.7,0,0,0-.92,2.46v7.2a7.42,7.42,0,0,1-.54,2.74A8.76,8.76,0,0,1,120,19.5a7.28,7.28,0,0,1-2.19,1.55A6.64,6.64,0,0,1,115.12,21.78Z"
          sx={{ fill: "primary" }}
        />
        <path
          d="M116.21,19.5v.55a5.67,5.67,0,0,0,4.19-5.47V7.38a5.2,5.2,0,0,1,3.56-5V1.82a5.69,5.69,0,0,0-4.19,5.56v7.2a5.19,5.19,0,0,1-3.56,4.92Z"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M.45.09H7.29a7.35,7.35,0,0,1,2.83.55,7.51,7.51,0,0,1,2.27,1.55A7.9,7.9,0,0,1,14,4.47a7.49,7.49,0,0,1,.54,2.82A7.58,7.58,0,0,1,14,10.12a6.85,6.85,0,0,1-1.56,2.28,7.66,7.66,0,0,1-2.27,1.54,7.44,7.44,0,0,1-2.83.64H3.64v6.75a.33.33,0,0,1-.09.27c-.09.09-.18.18-.27.18H.45c-.09,0-.18-.09-.27-.18S0,21.42,0,21.33V.46C0,.18.18.09.45.09ZM7.29,3.74H3.64v7.2H7.29a3.53,3.53,0,0,0,1.37-.28,3.36,3.36,0,0,0,1.18-.82,4.33,4.33,0,0,0,.73-1.09,3.65,3.65,0,0,0,.27-1.46,3.56,3.56,0,0,0-.27-1.37,4.87,4.87,0,0,0-.73-1.18A5.2,5.2,0,0,0,8.66,4,3.71,3.71,0,0,0,7.29,3.74Z"
          sx={{ fill: "primary" }}
        />
        <path
          d="M3.64,2.19H7.29a5.09,5.09,0,0,1,5.1,5.1,5.17,5.17,0,0,1-5.1,5.2H2.1V2.19Zm3.65-.55H1.55V20.23H2.1V13H7.29a5.7,5.7,0,0,0,5.65-5.74A5.56,5.56,0,0,0,7.29,1.64Z"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M90.45,21.87H79.05a.43.43,0,0,1-.45-.45V18.68a.43.43,0,0,1,.45-.45h11.4a.43.43,0,0,1,.45.45v2.74A.43.43,0,0,1,90.45,21.87ZM84.7,0a5.81,5.81,0,0,1,2.83.64,6.68,6.68,0,0,1,2.28,1.55,6,6,0,0,1,1.55,2.37A6.15,6.15,0,0,1,92,7.38a6.57,6.57,0,0,1-.63,2.92,7.13,7.13,0,0,1-3.83,3.92,7.18,7.18,0,0,1-2.83.55A7,7,0,0,1,82,14.22a8.3,8.3,0,0,1-2.37-1.55,10.77,10.77,0,0,1-1.55-2.37,7.81,7.81,0,0,1-.55-2.92,7.3,7.3,0,0,1,.55-2.82A8.84,8.84,0,0,1,79.6,2.19,8.09,8.09,0,0,1,82,.64,5.68,5.68,0,0,1,84.7,0Zm0,11a3.62,3.62,0,0,0,1.46-.28,2.46,2.46,0,0,0,1.1-.82,2.43,2.43,0,0,0,.81-1.09,3.65,3.65,0,0,0,.28-1.46,3.56,3.56,0,0,0-1.09-2.55,3.35,3.35,0,0,0-1.1-.73,3.8,3.8,0,0,0-1.46-.27,3.66,3.66,0,0,0-1.36.27,5.27,5.27,0,0,0-1.19.73A5.43,5.43,0,0,0,81.42,6a3.75,3.75,0,0,0-.27,1.37,3.83,3.83,0,0,0,.27,1.46,3.2,3.2,0,0,0,.73,1.09,3.39,3.39,0,0,0,1.19.82A3.49,3.49,0,0,0,84.7,11Z"
          sx={{ fill: "primary" }}
        />
        <polygon
          points="80.14 20.32 89.35 20.32 89.35 19.78 80.14 19.78 80.14 20.32"
          sx={{ fill: "#fff" }}
        />
        <path
          d="M84.7,12.58a5.24,5.24,0,0,1-5.1-5.2,5.15,5.15,0,0,1,5.1-5.1,5.27,5.27,0,0,1,5.11,4.1v.55A5.13,5.13,0,0,1,84.7,12.58Zm0-11a5.77,5.77,0,0,0-5.65,5.83,5.8,5.8,0,0,0,5.65,5.93,5.88,5.88,0,0,0,0-11.76Z"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M97.45.14h2.82a1,1,0,0,1,.64.27l5.2,5.29V.5c0-.27.09-.36.36-.36h2.83c.27,0,.36.09.36.36V21.37c0,.28-.09.37-.36.37h-2.83c-.27,0-.36-.09-.36-.37V11l-5.47-5.65v16c0,.28-.09.37-.37.37H97.45c-.27,0-.46-.09-.46-.37V.5C97,.23,97.18.14,97.45.14Z"
          sx={{ fill: "primary" }}
        />
        <polygon
          points="100.09 1.59 98.54 1.59 98.54 20.28 99.09 20.28 99.09 2.42 99.82 2.42 107.57 10.44 107.57 20.28 108.2 20.28 108.2 1.59 107.57 1.59 107.57 9.34 100.09 1.59"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M69.31,21.74c-.27,0-.45-.09-.45-.37V.5c0-.27.18-.36.45-.36h2.74c.27,0,.45.09.45.36V21.37c0,.28-.18.37-.45.37Z"
          sx={{ fill: "primary" }}
        />
        <polygon
          points="70.41 1.59 70.41 20.28 70.95 20.28 70.95 1.59 70.41 1.59"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M22.68,21.78H19.86a.35.35,0,0,1-.28-.09.33.33,0,0,1-.09-.27v-15A5.33,5.33,0,0,1,20,4a6.43,6.43,0,0,1,1.37-2,4.89,4.89,0,0,1,2-1.36A5.93,5.93,0,0,1,25.78.09a5.36,5.36,0,0,1,2.46.55,4.45,4.45,0,0,1,2,1.36,6.58,6.58,0,0,1,1.36,2,5.36,5.36,0,0,1,.55,2.46v15c0,.09-.09.18-.18.27a.33.33,0,0,1-.27.09H28.88a.32.32,0,0,1-.36-.36V14.58H23.05v6.84a.33.33,0,0,1-.09.27A.36.36,0,0,1,22.68,21.78Zm5.84-10.84V6.47a2.36,2.36,0,0,0-.82-1.91,2.63,2.63,0,0,0-1.92-.82,2.59,2.59,0,0,0-1.91.82,2.33,2.33,0,0,0-.82,1.91v4.47Z"
          sx={{ fill: "primary" }}
        />
        <path
          d="M30.06,6.47v6H21.59v-6a4.2,4.2,0,0,1,4.19-4.28,4.12,4.12,0,0,1,3,1.27A4.14,4.14,0,0,1,30.06,6.47ZM21,20.33h.64V13h8.47v7.3h.55V6.47A4.58,4.58,0,0,0,29.15,3a4.67,4.67,0,0,0-3.37-1.37A4.87,4.87,0,0,0,21,6.47Z"
          sx={{ fill: "#fff" }}
        />
      </g>
    </svg>
  )
}
export default PassionText
