import React, { useRef } from "react"
/** @jsx jsx */
import { jsx, Flex, Box, Text, useThemeUI } from "theme-ui"
import { usePageContext } from "../../../context/PageContext"
import ParallaxHorizontal from "../ParallaxHorizontal"
import ParallaxImage from "../ParallaxImage"
import BackgroundBox from "./BackgroundBox"
import positionData from "./positionData"
import immigrantImg from "../../../images/immigrant.svg"
import curiousKidImg from "../../../images/curious_kid.svg"
import currentProfessionImg from "../../../images/current_profession.svg"
import BackgroundText from "./svgs/BackgroundText"
const imgWrapper = {
  flexGrow: 1,
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  padding: [3, 3, 4],
}
const Background = props => {
  const { vw, vh, height, start, end } = props.sectionSize
  const imagePositions = positionData(start, end, vw)
  const horizontalTextRef = useRef(null)
  const viewportAspectRatio = vw / vh
  const flexDirection =
    (viewportAspectRatio < 1.2) | (vw > 900) ? "column" : "row"
  const { theme, colorMode } = useThemeUI()
  const backgroundColor =
    colorMode === "default"
      ? theme.colors.background
      : theme.colors.modes[colorMode].background
  const textColor = theme.colors.modes.dark.background
  const { backgroundImages } = usePageContext()

  const widthDivider = viewportAspectRatio < 1.2 ? 1.2 : 2
  const heightDivider = viewportAspectRatio < 1.2 ? 2 : 1.5
  const backgroundBoxWidth = vw / widthDivider
  const backgroundBoxHeight = vh / heightDivider

  return (
    <Box
      id="background"
      sx={{
        height,
        position: "relative",
        // flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: `${backgroundColor}7a`,
          zIndex: 5,
        }}
      ></Box>
      <ParallaxHorizontal
        style={{
          position: "absolute",
          top: 0,
          zIndex: 6,
          fontSize: "48px",
        }}
        positionData={{
          vx: {
            scroll: {
              start: 0,
              end: vh,
            },
            start: 0,
            end: vw,
            // speed: vw / (1 * vh),
            clamp: false,
          },
        }}
      >
        <BackgroundText
          ref={horizontalTextRef}
          style={{ height: ["40px", "40px", "60px"] }}
        />
      </ParallaxHorizontal>
      <BackgroundBox
        positionData={{
          size: {
            scroll: {
              start: vh / 2,
              end: vh / 2 + 100,
            },
            clamp: true,
          },
          x: vw / 2 - backgroundBoxWidth / 2,
          y: vh / 2 - backgroundBoxHeight / 2,
          height: backgroundBoxHeight,
          width: backgroundBoxWidth,
        }}
      >
        <Flex
          sx={{
            flexDirection,
            height: "100%",
            width: "100%",
          }}
        >
          <Box sx={imgWrapper}>
            <img src={immigrantImg} />
          </Box>
          <Text
            sx={{
              fontSize: [3, 3, 5],
              padding: [3, 3, 4],
              textAlign: "center",
              alignSelf: "center",
              color: textColor,
            }}
          >
            I was a young immigrant
          </Text>
        </Flex>
      </BackgroundBox>
      <BackgroundBox
        positionData={{
          size: {
            scroll: {
              start: vh + vh / 2,
              end: vh + vh / 2 + 100,
            },
            clamp: true,
          },
          x: vw / 2 - backgroundBoxWidth / 2,
          y: vh + vh / 2 - backgroundBoxHeight / 2,
          height: backgroundBoxHeight,
          width: backgroundBoxWidth,
        }}
      >
        <Flex sx={{ flexDirection: "column", height: "100%", width: "100%" }}>
          <Box sx={imgWrapper}>
            <img src={curiousKidImg} />
          </Box>
          <Text
            sx={{
              fontSize: [3, 3, 5],
              padding: [3, 3, 4],
              textAlign: "center",
              color: textColor,
            }}
          >
            I was a curious kid. Received a technical education.
          </Text>
        </Flex>
      </BackgroundBox>
      <BackgroundBox
        positionData={{
          size: {
            scroll: {
              start: 2 * vh + vh / 2,
              end: 2 * vh + vh / 2 + 100,
            },
            clamp: true,
          },
          x: vw / 2 - backgroundBoxWidth / 2,
          y: 2 * vh + vh / 2 - backgroundBoxHeight / 2,
          height: backgroundBoxHeight,
          width: backgroundBoxWidth,
        }}
      >
        <Flex sx={{ flexDirection: "column", height: "100%", width: "100%" }}>
          <Box sx={imgWrapper}>
            <img src={currentProfessionImg} />
          </Box>
          <Text
            sx={{
              fontSize: [3, 3, 5],
              padding: [3, 3, 4],
              textAlign: "center",
              color: textColor,
            }}
          >
            I have a data-centered career. Still curious.
          </Text>
        </Flex>
      </BackgroundBox>
      {imagePositions.map((positionData, i) => {
        const imgData = backgroundImages[i].node
        return (
          <ParallaxImage
            key={i}
            imgData={imgData}
            positionData={positionData}
          />
        )
      })}
    </Box>
  )
}
export default Background
