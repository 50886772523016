// import {getRandomInt} from "../../../utils"
// const maxWidth = 500
const positionData = (yStartPos, yEndPos, vw) => {
  const yHeight = yEndPos - yStartPos
  const xBuffer = 0.1 * vw > 100 ? 100 : 0.1 * vw
  // const yBuffer = 150
  return [
    {
      top: yStartPos + 0.1 * yHeight,
      left: xBuffer + vw * 0.1,
      width: 0.2 * vw,
      height: "auto",
      speed: 1,
    },
    {
      top: yStartPos + 0.05 * yHeight,
      left: xBuffer + vw * 0.03,
      width: 0.04 * vw,
      height: "auto",
      speed: 1,
    },
    {
      top: yStartPos + 0.0 * yHeight,
      left: vw - xBuffer - vw * 0.25,
      width: 0.2 * vw,
      height: "auto",
      speed: 0,
    },
    {
      top: yStartPos + 0.18 * yHeight,
      left: vw - xBuffer - vw * 0.15,
      width: 0.25 * vw,
      height: "auto",
      speed: 2,
    },
    {
      top: yStartPos + 0.4 * yHeight,
      left: xBuffer + vw * 0.1,
      width: 0.4 * vw,
      height: "auto",
      speed: 3,
    },
    {
      top: yStartPos + 0.22 * yHeight,
      left: xBuffer + vw * 0.4,
      width: 0.25 * vw,
      height: "auto",
      speed: 0,
    },
    {
      top: yStartPos + 0.3 * yHeight,
      left: xBuffer + vw * 0.1,
      width: 0.15 * vw,
      height: "auto",
      speed: 2,
    },
    {
      top: yStartPos + 0.3 * yHeight,
      left: xBuffer + vw * 0.1,
      width: 0.07 * vw,
      height: "auto",
      speed: 3,
    },
    {
      top: yStartPos + 0.21 * yHeight,
      left: xBuffer - 90,
      width: 0.3 * vw,
      height: "auto",
      speed: 4,
    },
    {
      top: yStartPos + 0.3 * yHeight,
      left: xBuffer + 10,
      width: 0.02 * vw,
      height: "auto",
      speed: 4,
    },
    {
      top: yStartPos + 0.4 * yHeight,
      left: xBuffer + vw * 0.1,
      width: 0.01 * vw,
      height: "auto",
      speed: 2,
    },
    {
      top: yStartPos + 0.23 * yHeight,
      left: vw - xBuffer - vw * 0.1,
      width: 0.2 * vw,
      height: "auto",
      speed: 1,
    },
    {
      top: yStartPos + 0.35 * yHeight,
      left: vw - xBuffer - vw * 0.2,
      width: 0.15 * vw,
      height: "auto",
      speed: 3,
    },
    {
      top: yStartPos + 0.27 * yHeight,
      left: xBuffer,
      width: 0.2 * vw,
      height: "auto",
      speed: 4,
    },
    {
      top: yStartPos + 0.4 * yHeight,
      left: vw - xBuffer - vw * 0.18,
      width: 0.18 * vw,
      height: "auto",
      speed: 0,
    },
    {
      top: yStartPos + 0.45 * yHeight,
      left: xBuffer + vw * 0.23,
      width: 0.12 * vw,
      height: "auto",
      speed: 1,
    },
    {
      top: yStartPos + 0.48 * yHeight,
      left: xBuffer + vw * 0.08,
      width: 0.19 * vw,
      height: "auto",
      speed: 2,
    },
    {
      top: yStartPos + 0.12 * yHeight,
      left: xBuffer + vw * 0.01,
      width: 0.15 * vw,
      height: "auto",
      speed: 0,
    },
    {
      top: yStartPos + 0.31 * yHeight,
      left: vw - xBuffer - vw * 0.02,
      width: 0.13 * vw,
      height: "auto",
      speed: 3,
    },
    {
      top: yStartPos + 0.51 * yHeight,
      left: vw - xBuffer - vw * 0.07,
      width: 0.21 * vw,
      height: "auto",
      speed: 1,
    },
    {
      top: yStartPos + 0.51 * yHeight,
      left: xBuffer + vw * 0.09,
      width: 0.19 * vw,
      height: "auto",
      speed: 0,
    },
    {
      top: yStartPos + 0.65 * yHeight,
      left: xBuffer - 50,
      width: 0.25 * vw,
      height: "auto",
      speed: 4,
    },
    {
      top: yStartPos + 0.56 * yHeight,
      left: xBuffer + vw * 0.4,
      width: 0.05 * vw,
      height: "auto",
      speed: 1,
    },
    {
      top: yStartPos + 0.51 * yHeight,
      left: vw - xBuffer - vw * 0.07,
      width: 0.21 * vw,
      height: "auto",
      speed: 1,
    },
    {
      top: yStartPos + 0.45 * yHeight,
      left: vw - xBuffer - vw * 0.1,
      width: 0.08 * vw,
      height: "auto",
      speed: 1,
    },
    {
      top: yStartPos + 0.71 * yHeight,
      left: vw - xBuffer - vw * 0.1,
      width: 0.17 * vw,
      height: "auto",
      speed: 1,
    },
    {
      top: yStartPos + 0.57 * yHeight,
      left: vw - xBuffer - vw * 0.22,
      width: 0.11 * vw,
      height: "auto",
      speed: 0,
    },
    {
      top: yStartPos + 0.57 * yHeight,
      left: vw - xBuffer - vw * 0.19,
      width: 0.06 * vw,
      height: "auto",
      speed: 3,
    },
    {
      top: yStartPos + 0.71 * yHeight,
      left: xBuffer + vw * 0.01,
      width: 0.2 * vw,
      height: "auto",
      speed: 2,
    },
    {
      top: yStartPos + 0.75 * yHeight,
      left: vw - xBuffer - vw * 0.19,
      width: 0.07 * vw,
      height: "auto",
      speed: 2,
    },
    {
      top: yStartPos + 0.75 * yHeight,
      left: xBuffer + vw * 0.25,
      width: 0.15 * vw,
      height: "auto",
      speed: 2,
    },
    {
      top: yStartPos + 0.8 * yHeight,
      left: xBuffer + vw * 0.01,
      width: 0.11 * vw,
      height: "auto",
      speed: 3,
    },
    {
      top: yStartPos + 0.85 * yHeight,
      left: xBuffer + vw * 0.03,
      width: 0.21 * vw,
      height: "auto",
      speed: 2,
    },
    {
      top: yStartPos + 0.83 * yHeight,
      left: vw - xBuffer - vw * 0.05,
      width: 0.11 * vw,
      height: "auto",
      speed: 2,
    },
    {
      top: yStartPos + 0.75 * yHeight,
      left: vw - xBuffer - vw * 0.3,
      width: 0.16 * vw,
      height: "auto",
      speed: 1,
    },
  ]
}

export default positionData
