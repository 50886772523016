import * as React from "react"
import { useState, useRef } from "react"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import Image from "gatsby-image"

const styleImg = {
  width: 500,
  height: 400,
  left: 0,
  position: "absolute",
  top: 0,
  zIndex: 4,
  marginBottom: "8px",
  // border: "1px solid red",
  // backgroundColor: "navy",
}
const ParallaxImage = ({ key, imgData, positionData }) => {
  // const [elementTop, setElementTop] = useState(0)
  const ref = useRef(null)
  const { scrollY } = useViewportScroll()

  const width = positionData.width
  const height = positionData.height
  const x = positionData.left
  const y = useTransform(
    scrollY,
    [positionData.top, positionData.top + 1],
    [positionData.top, positionData.top - positionData.speed],
    {
      clamp: false,
    }
  )

  return (
    <motion.div ref={ref} style={{ ...styleImg, y, x, width, height }}>
      <Image fluid={imgData.fluid} style={{ height: "100%", width: "100%" }} />
    </motion.div>
  )
}

export default ParallaxImage
