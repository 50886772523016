import React from "react"
/** @jsx jsx */
import { jsx, Container, Flex, Text } from "theme-ui"
import ParallaxHorizontal from "../ParallaxHorizontal"
import Violin from "./svgs/Violin"
import ComputersSvg from "./svgs/ComputersSvg"
import OutdoorsSvg from "./svgs/OutdoorsSvg"
import EarthSvg from "./svgs/EarthSvg"
import ReadSvg from "./svgs/ReadSvg"
import ConserveSvg from "./svgs/ConserveSvg"
import PassionText from "./svgs/PassionText"

const passionText = {
  // padding: [3, 3, 6],
  fontSize: ["16px", "24px", "38px"],
  fontWeight: "300",
  textAlign: "center",
  alignSelf: "center",
  width: "50%",
}

const passionImage = {
  height: "80%",
  width: "50%",
  // padding: [3, 3, 4],
}
const leftText = {
  paddingLeft: [3, 3, 5],
  paddingRight: [2, 2, 3],
}
const rightText = {
  paddingLeft: [2, 2, 3],
  paddingRight: [3, 3, 5],
}

const rightPanel = {
  width: "50%",
  justifyContent: "flex-start",
  // paddingLeft: [2, 2, 3, 5],
}
// const imagePanel = {
//   // border: "1px solid tomato",
//   // borderRadius: "500px",
//   // shapeOutside: "circle()",
// }
// const textPanel = {
//   flexDirection: "column",
//   justifyContent: "center",
// }

const Passions = props => {
  const { vw, vh, width, height, start } = props.sectionSize

  return (
    <Flex
      id="passions"
      sx={{
        position: "relative",
        height,
        width,
        flexDirection: "column",
      }}
    >
      <ParallaxHorizontal
        style={{
          position: "relative",
          top: 0,
          zIndex: 6,
        }}
        positionData={{
          vx: {
            scroll: {
              start: start - vh,
              end: start,
            },
            start: 0,
            end: vw,
            clamp: false,
          },
        }}
      >
        <PassionText style={{ height: ["40px", "40px", "60px"] }} />
      </ParallaxHorizontal>
      <Container>
        <Flex
          sx={{
            width: "100%",
            height: vh,
            flexDirection: "column",
          }}
        >
          <Flex sx={{ width: "100%", height: "50%" }}>
            {/* <Flex sx={leftPanel}> */}
            <Violin
              style={{
                ...passionImage,
                // marginRight: ["8px", "8px", "24px"],
              }}
            />
            {/* </Flex> */}
            {/* <Flex sx={{ ...rightPanel, ...textPanel }}> */}
            {/* <Text sx={{ ...passionText, ...alignSelfStart }}>             */}
            <Text sx={{ ...passionText, ...rightText }}>
              I like to play the violin
            </Text>
            {/* <Text sx={{ ...passionText, ...alignSelfStart }}>
              play the violin
            </Text> */}
            {/* </Flex> */}
          </Flex>
          <Flex sx={{ width: "100%", height: "50%" }}>
            {/* <Flex sx={{ ...leftPanel, ...textPanel }}> */}
            {/* <Text sx={{ ...passionText, ...alignSelfEnd }}> */}
            <Text sx={{ ...passionText, ...leftText }}>
              I like to tell computers what to do
            </Text>
            {/* <Text sx={{ ...passionText, ...alignSelfEnd }}>
              computers what to do
            </Text> */}
            {/* </Flex> */}
            {/* <Flex sx={rightPanel}> */}
            <ComputersSvg style={passionImage} />
            {/* </Flex> */}
          </Flex>
        </Flex>
        <Flex
          sx={{
            width: "100%",
            height: vh,
            flexDirection: "column",
          }}
        >
          <Flex sx={{ width: "100%", height: "50%" }}>
            {/* <Flex sx={leftPanel}> */}
            <OutdoorsSvg
              style={{ ...passionImage, marginRight: ["8px", "8px", "24px"] }}
            />
            {/* </Flex> */}
            {/* <Flex sx={{ ...rightPanel, ...textPanel }}> */}
            {/* <Text sx={{ ...passionText, ...alignSelfStart }}> */}
            <Text sx={{ ...passionText, ...rightText }}>
              I like the outdoors
            </Text>
            {/* <Text sx={{ ...passionText, ...alignSelfStart }}>the outdoors</Text> */}
            {/* </Flex> */}
          </Flex>
          <Flex sx={{ width: "100%", height: "50%" }}>
            {/* <Flex sx={{ ...leftPanel, ...textPanel }}> */}
            {/* <Text sx={{ ...passionText, ...alignSelfEnd }}> */}
            <Text sx={{ ...passionText, ...leftText }}>
              I like to travel this beautiful blue marble
            </Text>
            {/* <Text sx={{ ...passionText, ...alignSelfEnd }}>
              this beautiful blue marble
            </Text> */}
            {/* </Flex> */}
            {/* <Flex sx={rightPanel}> */}
            <EarthSvg style={passionImage} />
            {/* </Flex> */}
          </Flex>
        </Flex>

        <Flex
          sx={{
            width: "100%",
            height: vh,
            flexDirection: "column",
          }}
        >
          <Flex sx={{ width: "100%", height: "50%" }}>
            {/* <Flex sx={leftPanel}> */}
            <ReadSvg
              style={{ ...passionImage, marginRight: ["8px", "8px", "24px"] }}
            />
            {/* </Flex> */}
            {/* <Flex sx={{ ...rightPanel, ...textPanel }}> */}
            {/* <Text sx={{ ...passionText, ...alignSelfStart }}> */}
            <Text sx={{ ...passionText, ...rightText }}>I like to read</Text>
            {/* <Text sx={{ ...passionText, ...alignSelfStart }}>to read</Text> */}
            {/* </Flex> */}
          </Flex>
          <Flex sx={{ width: "100%", height: "50%" }}>
            {/* <Flex sx={{ ...leftPanel, ...textPanel }}> */}
            {/* <Text sx={{ ...passionText, ...alignSelfEnd }}> */}
            <Text sx={{ ...passionText, ...leftText }}>I like to conserve</Text>
            {/* <Text sx={{ ...passionText, ...alignSelfEnd }}>to conserve</Text> */}
            {/* </Flex> */}
            <Flex sx={rightPanel}>
              <ConserveSvg style={passionImage} />
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  )
}

export default Passions
