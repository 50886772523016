import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

const ValuesText = props => {
  const style = props.style

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 96.37 21.69"
      sx={style}
    >
      <g>
        <path
          d="M11.57.37A.51.51,0,0,1,12.12,0H15a.33.33,0,0,1,.27.09V.37L9.75,21.24c-.09.27-.18.45-.46.45H6c-.09,0-.18-.09-.27-.09a.47.47,0,0,1-.18-.36L0,.37A.34.34,0,0,1,.09.09C.09,0,.18,0,.36,0H3.19a.51.51,0,0,1,.55.37L7.66,16.86Z"
          sx={{ fill: "primary" }}
        />
        <polygon
          points="13.49 1.55 12.85 1.55 8.11 19.5 7.2 19.5 2.46 1.55 1.82 1.55 6.83 20.14 8.48 20.14 13.49 1.55"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M55.19,0c.28,0,.46.09.46.37V15.31a3,3,0,0,0,.73,2,3,3,0,0,0,3.92,0,2.65,2.65,0,0,0,.73-2V.37c0-.28.18-.37.45-.37h2.74c.36,0,.45.09.45.37V15.31a7,7,0,0,1-.45,2.46,6.6,6.6,0,0,1-1.37,2,5,5,0,0,1-2,1.37,6.08,6.08,0,0,1-2.46.54,5.49,5.49,0,0,1-2.46-.54,4.39,4.39,0,0,1-2-1.37,4.39,4.39,0,0,1-1.37-2A5.49,5.49,0,0,1,52,15.31V.37c0-.1.09-.19.19-.28A.33.33,0,0,1,52.46,0Z"
          sx={{ fill: "primary" }}
        />
        <path
          d="M54.1,15.31V1.55h-.55V15.31a4.86,4.86,0,0,0,4.83,4.83h.46a4.77,4.77,0,0,0,4.28-4.83V1.55h-.54V15.31a4.21,4.21,0,0,1-4.2,4.29A4.28,4.28,0,0,1,54.1,15.31Z"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M86,21.69c-.18,0-.27,0-.36-.09a.33.33,0,0,1-.09-.27V18.5c0-.27.09-.36.45-.45a3,3,0,0,0,1.19-.37,2.77,2.77,0,0,0,1-.82,2.27,2.27,0,0,0,.73-1.09,3,3,0,0,0,.27-1.28V7.29a6.7,6.7,0,0,1,.46-2.73,8,8,0,0,1,1.46-2.28A7.16,7.16,0,0,1,93.27.73,6.64,6.64,0,0,1,95.91,0a.44.44,0,0,1,.46.46V3.19c0,.09-.1.18-.1.27a.48.48,0,0,1-.36.19,3.3,3.3,0,0,0-2.28,1.18,3.74,3.74,0,0,0-.91,2.46v7.2a7.24,7.24,0,0,1-.55,2.74,8.73,8.73,0,0,1-1.36,2.18A7.28,7.28,0,0,1,88.62,21,6.64,6.64,0,0,1,86,21.69Z"
          sx={{ fill: "primary" }}
        />
        <path
          d="M87.07,19.41V20a5.67,5.67,0,0,0,4.19-5.47V7.29a5.2,5.2,0,0,1,3.56-5V1.73a5.7,5.7,0,0,0-4.2,5.56v7.2a5.19,5.19,0,0,1-3.55,4.92Z"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M38.44,0h2.73c.09,0,.18.09.27.18s.19.18.19.28V18.14h5c.27,0,.36.09.36.36v2.83c0,.27-.09.36-.36.36h-8.2c-.19,0-.28,0-.37-.09a.33.33,0,0,1-.09-.27V.46a.34.34,0,0,1,.09-.28A.47.47,0,0,1,38.44,0Z"
          sx={{ fill: "primary" }}
        />
        <polygon
          points="40.08 1.55 39.53 1.55 39.53 20.23 45.54 20.23 45.54 19.6 40.08 19.6 40.08 1.55"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M70,0h10a.43.43,0,0,1,.46.46V3.19a.44.44,0,0,1-.46.46H73.23V7.29h5c.27,0,.46.09.46.37v2.82c0,.09-.09.18-.19.27a.31.31,0,0,1-.27.1h-5v7.29h6.83c.28,0,.46.09.46.36v2.83c0,.09-.09.18-.18.27a.35.35,0,0,1-.28.09H70a.35.35,0,0,1-.28-.09.33.33,0,0,1-.09-.27V.46C69.67.18,69.76,0,70,0Z"
          sx={{ fill: "primary" }}
        />
        <polygon
          points="78.97 1.55 71.13 1.55 71.13 20.23 78.97 20.23 78.97 19.6 71.77 19.6 71.77 9.39 77.15 9.39 77.15 8.75 71.77 8.75 71.77 2.1 78.97 2.1 78.97 1.55"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M23.5,21.69H20.67a.32.32,0,0,1-.36-.36V6.38a5.33,5.33,0,0,1,.46-2.46,6.58,6.58,0,0,1,1.36-2,5,5,0,0,1,2-1.36A5.93,5.93,0,0,1,26.6,0a5.36,5.36,0,0,1,2.46.55,4.45,4.45,0,0,1,2,1.36,6.58,6.58,0,0,1,1.36,2A5.36,5.36,0,0,1,33,6.38v15c0,.09-.09.18-.18.27a.34.34,0,0,1-.28.09H29.7a.36.36,0,0,1-.28-.09.33.33,0,0,1-.09-.27V14.49H23.87v6.84a.31.31,0,0,1-.1.27A.33.33,0,0,1,23.5,21.69Zm5.83-10.84V6.38a2.33,2.33,0,0,0-.82-1.91,2.63,2.63,0,0,0-3.82,0,2.33,2.33,0,0,0-.82,1.91v4.47Z"
          sx={{ fill: "primary" }}
        />
        <path
          d="M30.88,6.38v6H22.41v-6A4.2,4.2,0,0,1,26.6,2.1a4.14,4.14,0,0,1,3,1.27A4.12,4.12,0,0,1,30.88,6.38ZM21.77,20.23h.64V12.94h8.47v7.29h.55V6.38A4.61,4.61,0,0,0,30,2.92,4.67,4.67,0,0,0,26.6,1.55a4.87,4.87,0,0,0-4.83,4.83Z"
          sx={{ fill: "#fff" }}
        />
      </g>
    </svg>
  )
}
export default ValuesText
