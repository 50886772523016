import React from "react"
/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"
import { Tooltip } from "react-tippy"

const circle = {
  borderRadius: "50px",
  borderColor: "muted",
  borderStyle: "solid",
  borderWidth: "2px",
  cursor: "pointer",
}

const Circle = ({ width, height, id, clickHandler, selected }) => {
  return (
    <Box
      id={id}
      onClick={element => {
        clickHandler(element)
      }}
      sx={{
        ...circle,
        ...{
          width,
          height,
          margin: "2px",
          backgroundColor: selected ? "muted" : null,
        },
      }}
    />
  )
}

const CircleNav = props => {
  const { data, circleSize, flexDirection, clickHandler } = props
  return (
    <Flex sx={{ flexDirection: flexDirection }}>
      {data.map((circle, i) => {
        return (
          <Tooltip {...circle.tooltip}>
            <Circle
              key={circle.name}
              id={circle.name}
              selected={circle.selected}
              width={circleSize}
              height={circleSize}
              clickHandler={element => clickHandler(element)}
            />
          </Tooltip>
        )
      })}
    </Flex>
  )
}
export default CircleNav

CircleNav.defaultProps = {
  circleSize: 10,
  flexDirection: "row",
  clickHandler: () => console.log("clicked on circle nav"),
  data: [
    {
      name: "circle1",
      selected: true,
    },
    {
      name: "circle2",
      selected: false,
    },
  ],
}
