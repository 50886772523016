import React, { useState, useEffect, useRef } from "react"
/** @jsx jsx */
import { jsx, Container, Flex, Box, Heading, Text, Link } from "theme-ui"
import CircleNav from "../../CircleNav"
import Background from "./Background"
import Passions from "./Passions"
import Values from "./Values"
import Image from "gatsby-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useAppContext } from "../../../context/AppContext"
import { usePageContext } from "../../../context/PageContext"

const pageSectionsNav = {
  position: "fixed",
  right: 0,
  top: "50%",
  zIndex: 10,
}
const basePageSections = [
  {
    name: "background",
    selected: true,
    tooltip: { title: "Background", position: "left" },
  },
  {
    name: "passions",
    selected: false,
    tooltip: { title: "Passions", position: "left" },
  },
  {
    name: "values",
    selected: false,
    tooltip: { title: "Values", position: "left" },
  },
]

const getSectionIdx = (sectionRanges, yPos) => {
  const idx = sectionRanges.reduce((acc, section, i) => {
    if ((yPos >= section.start) & (yPos < section.end)) {
      acc.push(i)
    }
    return acc
  }, [])
  return idx[0]
}

const Personal = () => {
  const { size } = useAppContext()
  const { aboutImg } = usePageContext()
  const lastSectionIdx = useRef(0)
  const [pageSections, setPageSections] = useState(basePageSections)
  const sectionClickHandler = element => {
    const id = element.currentTarget.id
    let newPageSections = [...pageSections]
    newPageSections = newPageSections.map(data => {
      data.selected = false
      if (id === data.name) {
        data.selected = true
      }
      return data
    })
    setPageSections(newPageSections)
    // console.log(`#${id}`)
    // console.log(document.querySelector(`#${id}`))
    // scrollTo(`#test`)
    scrollTo(`#${id}`)
  }

  const vh = size.viewport.height
  const vw = size.viewport.width
  const xBuffer = 0.1 * vw > 100 ? 100 : 0.1 * vw
  const yBuffer = 50

  const backgroundSectionSize = {
    vw,
    vh,
    xBuffer,
    yBuffer,
    height: 3 * vh,
    width: vw,
    start: 0,
    end: 4 * vh,
  }
  const passionSectionSize = {
    vw,
    vh,
    xBuffer,
    yBuffer,
    height: 3 * vh,
    width: vw,
    start: backgroundSectionSize.end,
    end: backgroundSectionSize.end + 3 * vh,
  }
  const valuesSectionSize = {
    vw,
    vh,
    xBuffer,
    yBuffer,
    height: vh,
    width: vw,
    start: passionSectionSize.end,
    end: passionSectionSize.end + vh,
  }

  const sectionRanges = [
    backgroundSectionSize,
    passionSectionSize,
    valuesSectionSize,
  ].map(item => {
    const { start, end } = item
    return { start, end }
  })

  useEffect(() => {
    const handleScroll = () => {
      const yPos = window.scrollY
      const sectionIdx = getSectionIdx(sectionRanges, yPos)
      if (sectionIdx !== lastSectionIdx.current) {
        lastSectionIdx.current = sectionIdx
        const newPageSections = pageSections.map((section, i) => ({
          ...section,
          selected: sectionIdx === i ? true : false,
        }))
        setPageSections(newPageSections)
      }
    }
    window.addEventListener("scroll", handleScroll, false)

    return () => {
      window.removeEventListener("scroll", handleScroll, false)
    }
  }, [sectionRanges]) // Empty array ensures that effect is only run on mount

  return (
    <Flex
      id="aboutImgWrapper"
      sx={{
        flexDirection: "column",
        width: vw,
        height: valuesSectionSize.end,
      }}
    >
      <Container sx={{ minWidth: ["100%", "100%", "1200px"] }}>
        {/* <Container sx={{ minWidth: "1200px" }}> */}
        <Flex
          id="aboutImg"
          py={3}
          sx={{
            width: "100%",
            height: `${vh}px`,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Flex
            sx={{
              width: "100%",
              height: ["90%", "90%", "70%"],
              flexDirection: ["column-reverse", "column-reverse", "row"],
            }}
          >
            <Box
              sx={{
                width: ["100%", "100%", "40%"],
                paddingLeft: [0, 0, 5],
                alignSelf: "flex-end",
              }}
            >
              <Heading as="h2" p={3} sx={{ textAlign: "right" }}>
                About Me
              </Heading>
              <Text p={3} sx={{ textAlign: "justify" }}>
                Hello from dreds! I am a life-long learner with mathematical
                training and a predilection for art, data and tech. My goals are
                to find/tell compelling stories and have a positive impact. I am
                always looking for a thought-provoking challenge. Here is my{" "}
                {<Link href="/CV">CV</Link>}. Feel free to say hi.
              </Text>
            </Box>
            <Flex
              sx={{
                width: ["100%", "100%", "60%"],
                alignSelf: "center",
                height: "100%",
                justifyContent: "center",
              }}
              p={3}
            >
              <Image
                fluid={aboutImg.childImageSharp.fluid}
                sx={{ width: ["100%", "100%", "90%"], height: "100%" }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Container>
      <Background sectionSize={backgroundSectionSize} />
      <Passions sectionSize={passionSectionSize} />
      <Values sectionSize={valuesSectionSize} />
      {size.headerSize === "large" ? (
        <Box sx={pageSectionsNav} mr={3}>
          <CircleNav
            data={pageSections}
            circleSize={15}
            flexDirection="column"
            justifyContent="center"
            clickHandler={element => sectionClickHandler(element)}
          />
        </Box>
      ) : null}
    </Flex>
  )
}

export default Personal
