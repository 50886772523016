import React from "react"
/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import ParallaxHorizontal from "../ParallaxHorizontal"
import ValuesText from "./svgs/ValuesText"
import waves from "../../../images/waves.svg"
import ValueBox from "./ValueBox"

const backgroundImage = {
  position: "relative",
  width: "100%",
  height: "100%",
  backgroundImage: `url(${waves})`,
  backgroundSize: "cover",
  justifyContent: "center",
}

const Values = props => {
  const { vw, vh, width, height, start } = props.sectionSize
  return (
    <Flex
      id="values"
      sx={{
        position: "relative",
        height,
        width,
        flexDirection: "column",
      }}
    >
      <ParallaxHorizontal
        style={{
          position: "relative",
          top: 0,
          zIndex: 6,
          // fontSize: "48px",
          padding: "50px 0px 48px 0px",
        }}
        positionData={{
          vx: {
            scroll: {
              start: start - vh,
              end: start,
            },
            start: 0,
            end: vw,
            clamp: false,
          },
        }}
      >
        <ValuesText style={{ height: ["40px", "40px", "60px"] }} />
      </ParallaxHorizontal>
      <Flex sx={backgroundImage}>
        <ValueBox />
      </Flex>
    </Flex>
  )
}
export default Values
