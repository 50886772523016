import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
/** @jsx jsx */
import { jsx, Box } from "theme-ui"
// CUSTOM COMPONENTS
// import Social from "../components/Social"
import AboutMe from "../components/about"
import { PageContextProvider } from "../context/PageContext"

// const socialNav = {
//   position: "fixed",
//   left: 0,
//   top: "50%",
//   zIndex: 10000,
//   display: ["none", "none", "block"],
// }
// const aboutCategories = {
//   position: "fixed",
//   top: 0,
//   left: "50%",
//   zIndex: 10000,
// }
const About = ({ data }) => {
  const { aboutImg, assets } = data
  const backgroundImages = assets.edges
    .map(a => ({ sort: Math.random(), value: a }))
    .sort((a, b) => a.sort - b.sort)
    .map(a => a.value)
  return (
    <PageContextProvider contextData={{ aboutImg, backgroundImages }}>
      <Layout>
        {/* <Box sx={socialNav} ml={3}>
          <Social flexDirection="column" />
        </Box> */}
        <AboutMe />
      </Layout>
    </PageContextProvider>
  )
}
export const query = graphql`
  query {
    aboutImg: file(relativePath: { eq: "dreds_aboutme.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    assets: allContentfulAsset(
      filter: { title: { regex: "/^about-personal-background/" } }
    ) {
      edges {
        node {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
          title
        }
      }
    }
  }
`

export default About
