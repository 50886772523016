import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import {
  motion,
  useViewportScroll,
  useTransform,
  useSpring,
} from "framer-motion"

const boxStyle = {
  // "linear-gradient(to right, #f2f2f200 , #f2f2f2ff 30%, #f2f2f2cc 89%, #f2f2f200 90%)",
  // ##f2f2f200 5%, #f2f2f2ff 20%, #f2f2f2ff 80%, ##f2f2f200);
  backgroundColor: "#fafafaee",
  borderRadius: "4px",
  fontSize: "48px",
  zIndex: 5,
  position: "absolute",
  filter: "drop-shadow(1px 1px 7px #00000077)",
}
const BackgroundBox = props => {
  const { size, x, y, width, height } = props.positionData
  const { scrollY } = useViewportScroll()
  const tScale = useTransform(
    scrollY,
    [size.scroll.start, size.scroll.start + 50],
    [0, 1],
    { clamp: size.clamp }
  )
  const scale = useSpring(tScale, { stiffness: 100, damping: 15 })
  return (
    <motion.div
      style={{
        ...boxStyle,
        width,
        height,
        x,
        y,
        scale,
      }}
    >
      {props.children}
    </motion.div>
  )
}
export default BackgroundBox
