import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

const BackgroundText = props => {
  const style = props.style

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 182.39 21.87"
      sx={style}
    >
      <g>
        <path
          d="M135.75.09c.27,0,.45.09.45.37V15.4a3.05,3.05,0,0,0,.73,2,3.05,3.05,0,0,0,2,.73,3,3,0,0,0,1.91-.73,2.62,2.62,0,0,0,.73-2V.46c0-.28.19-.37.46-.37h2.73c.37,0,.46.09.46.37V15.4a6.82,6.82,0,0,1-.46,2.46,6.43,6.43,0,0,1-1.37,2,4.92,4.92,0,0,1-2,1.37,6.08,6.08,0,0,1-2.46.54,5.49,5.49,0,0,1-2.46-.54,4.47,4.47,0,0,1-2-1.37,4.45,4.45,0,0,1-1.36-2,5.36,5.36,0,0,1-.55-2.46V.46c0-.1.09-.19.18-.28A.34.34,0,0,1,133,.09Z"
          sx={{
            fill: "primary",
          }}
        />
        <path
          d="M134.66,15.4V1.64h-.55V15.4a4.86,4.86,0,0,0,4.83,4.83h.45a4.77,4.77,0,0,0,4.29-4.83V1.64h-.55V15.4a4.2,4.2,0,0,1-4.19,4.29A4.28,4.28,0,0,1,134.66,15.4Z"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M100.78.09a7.3,7.3,0,0,1,2.82.55,8.25,8.25,0,0,1,2.28,1.55,8.3,8.3,0,0,1,1.55,2.37,7.2,7.2,0,0,1,.64,2.82A6.59,6.59,0,0,1,107,11a7.42,7.42,0,0,1-2.64,2.64l3.56,7.75c.09.27,0,.36-.28.36h-3.19a.33.33,0,0,1-.27-.09c-.18-.09-.28-.18-.28-.27l-1.27-2.83L99.32,11.3c-.19-.18-.09-.36.18-.36h1.28a3.69,3.69,0,0,0,1.46-.28,4.52,4.52,0,0,0,1.09-.73,3.49,3.49,0,0,0,.82-1.18,3.71,3.71,0,0,0,.27-1.37,3.87,3.87,0,0,0-.27-1.46,2.51,2.51,0,0,0-.82-1.09A3.27,3.27,0,0,0,102.24,4a3.87,3.87,0,0,0-1.46-.27H97.22V21.42c0,.09-.09.18-.18.27s-.18.09-.27.09H93.94a.33.33,0,0,1-.36-.36V.55c0-.28.09-.46.36-.46Z"
          sx={{
            fill: "primary",
          }}
        />
        <path
          d="M100.78,1.64H95.12V20.23h.55v-18h5.11a5.09,5.09,0,0,1,5,4.1,5.14,5.14,0,0,1-4,6.11h-.37c1.19,2.64,2.46,5.28,3.56,7.83h.73l-3.37-7.29a5.94,5.94,0,0,0,4.19-6.2,1.14,1.14,0,0,1-.09-.54A5.76,5.76,0,0,0,100.78,1.64Z"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M126,21.87H114.62a.43.43,0,0,1-.46-.45V18.68a.43.43,0,0,1,.46-.45H126a.43.43,0,0,1,.46.45v2.74A.43.43,0,0,1,126,21.87ZM120.27,0a5.8,5.8,0,0,1,2.82.64,6.49,6.49,0,0,1,2.28,1.55,5.86,5.86,0,0,1,1.55,2.37,6,6,0,0,1,.64,2.82,6.44,6.44,0,0,1-.64,2.92,6.62,6.62,0,0,1-1.55,2.37,6.63,6.63,0,0,1-2.28,1.55,7.16,7.16,0,0,1-2.82.55,7,7,0,0,1-2.73-.55,8.21,8.21,0,0,1-2.38-1.55,10.74,10.74,0,0,1-1.54-2.37,7.81,7.81,0,0,1-.55-2.92,7.3,7.3,0,0,1,.55-2.82,8.82,8.82,0,0,1,1.54-2.37A8,8,0,0,1,117.54.64,5.66,5.66,0,0,1,120.27,0Zm0,11a3.65,3.65,0,0,0,1.46-.28,2.43,2.43,0,0,0,1.09-.82,2.38,2.38,0,0,0,.82-1.09,3.84,3.84,0,0,0,.28-1.46A3.77,3.77,0,0,0,123.64,6a3.45,3.45,0,0,0-.82-1.18,3.3,3.3,0,0,0-1.09-.73,3.83,3.83,0,0,0-1.46-.27,3.71,3.71,0,0,0-1.37.27,5.2,5.2,0,0,0-1.18.73A5.07,5.07,0,0,0,117,6a3.75,3.75,0,0,0-.27,1.37A3.83,3.83,0,0,0,117,8.84a3.06,3.06,0,0,0,.73,1.09,3.36,3.36,0,0,0,1.18.82A3.53,3.53,0,0,0,120.27,11Z"
          sx={{
            fill: "primary",
          }}
        />
        <polygon
          points="115.71 20.32 124.92 20.32 124.92 19.78 115.71 19.78 115.71 20.32"
          sx={{ fill: "#fff" }}
        />
        <path
          d="M120.27,12.58a5.24,5.24,0,0,1-5.11-5.2,5.16,5.16,0,0,1,5.11-5.1,5.25,5.25,0,0,1,5.1,4.1v.55A5.12,5.12,0,0,1,120.27,12.58Zm0-11a5.77,5.77,0,0,0-5.65,5.83,5.8,5.8,0,0,0,5.65,5.93A5.88,5.88,0,0,0,126,6.74,5.82,5.82,0,0,0,120.27,1.55Z"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M150.69.14h2.82a1,1,0,0,1,.64.27l5.19,5.29V.5c0-.27.1-.36.37-.36h2.82c.28,0,.37.09.37.36V21.37c0,.28-.09.37-.37.37h-2.82c-.27,0-.37-.09-.37-.37V11l-5.46-5.65v16c0,.28-.1.37-.37.37h-2.82c-.28,0-.46-.09-.46-.37V.5C150.23.23,150.41.14,150.69.14Z"
          sx={{
            fill: "primary",
          }}
        />
        <polygon
          points="153.33 1.59 151.78 1.59 151.78 20.28 152.33 20.28 152.33 2.42 153.06 2.42 160.8 10.44 160.8 20.28 161.44 20.28 161.44 1.59 160.8 1.59 160.8 9.34 153.33 1.59"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M69.05,21.42c.09.27,0,.36-.27.36h-3.1c-.18,0-.28,0-.37-.09s-.27-.18-.27-.27L60.3,10.94,58.39,13.4v8c0,.09-.09.18-.19.27a.33.33,0,0,1-.27.09H55.1c-.27,0-.36-.09-.36-.36V.55a.35.35,0,0,1,.09-.28c.09-.09.18-.18.27-.18h2.83c.09,0,.18.09.27.18s.19.19.19.28V7.66L64,.46a.81.81,0,0,1,.73-.37h3.82c.19,0,.28.09.09.37L62.85,7.75Z"
          sx={{
            fill: "primary",
          }}
        />
        <polygon
          points="66.13 20.28 66.86 20.28 61.12 7.52 65.86 1.59 65.04 1.59 56.84 11.89 56.84 1.59 56.29 1.59 56.29 20.28 56.84 20.28 56.84 12.9 60.66 8.07 66.13 20.28"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M86.39.18c.09,0,.18.09.27.18s.09.19.09.28V3.55c0,.19-.18.37-.36.28s-.46-.09-.73-.09h-.73a7.58,7.58,0,0,0-2.83.54,7.89,7.89,0,0,0-2.27,1.55,6.68,6.68,0,0,0-1.55,2.28,7.53,7.53,0,0,0,0,5.65A7.68,7.68,0,0,0,79.83,16a7.51,7.51,0,0,0,2.27,1.55,7.58,7.58,0,0,0,2.83.55v-7.2H83.56c-.09,0-.18-.09-.27-.09s-.18-.19-.18-.28V7.75c0-.09.09-.19.18-.28s.18-.18.27-.18h4.56a.47.47,0,0,1,.36.18c0,.09.1.19.1.28V21.42a.42.42,0,0,1-.46.36H84.93A10.65,10.65,0,0,1,80.74,21a11.67,11.67,0,0,1-3.47-2.37A9.82,9.82,0,0,1,75,15.13a9.37,9.37,0,0,1-.92-4.19A9.15,9.15,0,0,1,75,6.74a9.91,9.91,0,0,1,2.27-3.46A10.12,10.12,0,0,1,80.74,1,9.33,9.33,0,0,1,84.93.09h.73A2,2,0,0,0,86.39.18Z"
          sx={{
            fill: "primary",
          }}
        />
        <path
          d="M85.2,2.19V1.64a9.23,9.23,0,0,0-9.57,9.3,9.34,9.34,0,0,0,9.3,9.29H87V8.84H84.66v.55h1.82v10.3H84.93a8.75,8.75,0,1,1,.27-17.5Z"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M171.54.09a9.4,9.4,0,0,1,4.2.91,11,11,0,0,1,3.46,2.28,10.08,10.08,0,0,1,2.28,3.46,9.4,9.4,0,0,1,.91,4.2,9.74,9.74,0,0,1-.91,4.28,9.62,9.62,0,0,1-2.28,3.37A11,11,0,0,1,175.74,21a10.73,10.73,0,0,1-4.2.82h-3.19c-.18,0-.27,0-.36-.09a.33.33,0,0,1-.09-.27V.55a.43.43,0,0,1,.45-.46Zm0,18.14a7.4,7.4,0,0,0,2.83-.64A7.68,7.68,0,0,0,176.65,16a6.82,6.82,0,0,0,1.55-2.28,7.53,7.53,0,0,0,0-5.65,7.87,7.87,0,0,0-1.55-2.28,7.87,7.87,0,0,0-2.28-1.55,7.5,7.5,0,0,0-2.83-.54Z"
          sx={{
            fill: "primary",
          }}
        />
        <path
          d="M171.54,19.64H170V2.14c5.55,0,10.29,2.74,10.29,8.75A8.8,8.8,0,0,1,171.54,19.64Zm-2.09-18V20.28h2.09a9.35,9.35,0,0,0,9.3-9.39C180.84,4.06,175.55,1.59,169.45,1.59Z"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M49.28.18c.37.09.46.18.46.46V3.55a.34.34,0,0,1-.09.28.18.18,0,0,1-.28,0c-.27,0-.45-.09-.72-.09h-.73a7.5,7.5,0,0,0-2.83.54,7.87,7.87,0,0,0-2.28,1.55,7.87,7.87,0,0,0-1.55,2.28,7.66,7.66,0,0,0,0,5.65A6.82,6.82,0,0,0,42.81,16a7.68,7.68,0,0,0,2.28,1.55,7.4,7.4,0,0,0,2.83.64,9.09,9.09,0,0,0,1.45-.18c.28-.09.37.09.37.36v2.83a.33.33,0,0,1-.09.27.47.47,0,0,1-.37.18c-.18,0-.45.09-.63.09h-.73a10.73,10.73,0,0,1-4.2-.82,10,10,0,0,1-3.46-2.37A9.62,9.62,0,0,1,38,15.22a11.2,11.2,0,0,1-.91-4.28A10.8,10.8,0,0,1,38,6.74,10.29,10.29,0,0,1,43.72,1a9.4,9.4,0,0,1,4.2-.91C48.37.09,48.83.18,49.28.18Z"
          sx={{
            fill: "primary",
          }}
        />
        <path
          d="M48.19,2.14V1.6a9.21,9.21,0,0,0-9.57,9.29,3.41,3.41,0,0,0,.09,1,9.34,9.34,0,0,0,9.48,8.39v-.64a8.75,8.75,0,1,1,0-17.5Z"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M5.38.09A5.23,5.23,0,0,1,7.47.55,3.91,3.91,0,0,1,9.21,1.73a4.26,4.26,0,0,1,1.18,1.64,6,6,0,0,1,.46,2.19A5,5,0,0,1,10.21,8a7,7,0,0,1,1.73,1.09,5.81,5.81,0,0,1,1.37,1.55,6.7,6.7,0,0,1,.82,1.83,7.77,7.77,0,0,1,.27,2.09,7.32,7.32,0,0,1-.55,2.83,7.68,7.68,0,0,1-1.55,2.28A6.68,6.68,0,0,1,10,21.24a7.58,7.58,0,0,1-2.83.54H.36a.33.33,0,0,1-.27-.09A.33.33,0,0,1,0,21.42V.55C0,.27.09.09.36.09ZM3.56,7.38H5.38a1.63,1.63,0,0,0,1.27-.55A1.75,1.75,0,0,0,7.2,5.56a1.53,1.53,0,0,0-.55-1.28,1.66,1.66,0,0,0-1.27-.54H3.56ZM7.2,18.23a2.34,2.34,0,0,0,1.37-.37,3.59,3.59,0,0,0,1.18-.73A3.27,3.27,0,0,0,10.57,16a3.53,3.53,0,0,0,.28-1.37,3.69,3.69,0,0,0-.28-1.46A3,3,0,0,0,9.75,12a3.92,3.92,0,0,0-1.18-.82,3.38,3.38,0,0,0-1.37-.27H3.56v7.29Z"
          sx={{
            fill: "primary",
          }}
        />
        <path
          d="M7.2,19.64H2.1V9.43H7.2a5.28,5.28,0,0,1,3.65,1.46c.09.09.18.27.27.37a4.84,4.84,0,0,1,1.09,2.18,4.54,4.54,0,0,1,.09,1.1,5.26,5.26,0,0,1-.36,2A5,5,0,0,1,9.66,19,4.48,4.48,0,0,1,7.2,19.64ZM5.38,8.79H2.1V2.14H5.38A1.4,1.4,0,0,1,6,2.23a3.28,3.28,0,0,1,1.73.91,3.16,3.16,0,0,1,1,2.37A3.28,3.28,0,0,1,5.38,8.79Zm.82-7.1a2.34,2.34,0,0,0-.82-.1H1.46V20.28H7.2a5.87,5.87,0,0,0,5.29-3.56,6.25,6.25,0,0,0,.45-2.18,6.27,6.27,0,0,0-.18-1.28,5.18,5.18,0,0,0-1.55-2.83A5.93,5.93,0,0,0,7.57,8.79,4,4,0,0,0,9.3,5.51a3.64,3.64,0,0,0-.64-2.18A4.12,4.12,0,0,0,6.2,1.69Z"
          sx={{ fill: "#fff" }}
        />
      </g>
      <g>
        <path
          d="M22.59,21.78H19.77a.34.34,0,0,1-.28-.09.33.33,0,0,1-.09-.27v-15A5.33,5.33,0,0,1,19.86,4a6.58,6.58,0,0,1,1.36-2,5,5,0,0,1,2-1.36A5.93,5.93,0,0,1,25.69.09a5.36,5.36,0,0,1,2.46.55,4.45,4.45,0,0,1,2,1.36,6.58,6.58,0,0,1,1.36,2,5.36,5.36,0,0,1,.55,2.46v15c0,.09-.09.18-.18.27a.34.34,0,0,1-.28.09H28.79a.33.33,0,0,1-.27-.09.31.31,0,0,1-.1-.27V14.58H23v6.84a.33.33,0,0,1-.09.27A.36.36,0,0,1,22.59,21.78Zm5.83-10.84V6.47a2.33,2.33,0,0,0-.82-1.91,2.63,2.63,0,0,0-3.82,0A2.33,2.33,0,0,0,23,6.47v4.47Z"
          sx={{
            fill: "primary",
          }}
        />
        <path
          d="M30,6.43v6H21.5v-6a4.2,4.2,0,0,1,4.19-4.29,4.15,4.15,0,0,1,3,1.28A4.12,4.12,0,0,1,30,6.43ZM20.86,20.28h.64V13H30v7.29h.55V6.43A4.6,4.6,0,0,0,29.06,3a4.67,4.67,0,0,0-3.37-1.37,4.87,4.87,0,0,0-4.83,4.84Z"
          sx={{ fill: "#fff" }}
        />
      </g>
    </svg>
  )
}
export default BackgroundText
