import React from "react"
/** @jsx jsx */
import { jsx, Flex, Text } from "theme-ui"
const wrapper = {
  backgroundColor: "background",
  borderRadius: "4px",
  height: ["40%", "40%", "50%"],
  width: ["90%", "70%", "40%"],
  alignSelf: "center",
  justifyContent: "center",
  flexDirection: "column",
  position: "relative",
}
const textStyle = {
  fontSize: ["32px", "32px", "48px"],
  zIndex: 10000,
  alignSelf: "center",
  display: "block",
}

const ValueBox = () => {
  // const { theme } = useThemeUI()
  return (
    <Flex sx={wrapper}>
      <Text sx={{ ...textStyle }}>Love, Learn</Text>
      <Text sx={{ ...textStyle }}>and Live</Text>
    </Flex>
  )
}
export default ValueBox
